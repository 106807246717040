<template>
  <div class="page">
    <Header :progress-show="false" />

    <div class="welcome">
      <img src="@/assets/images/welcome.jpg">
    </div>

    <div class="description">description description description description description description description description description description description description description</div>

    <div class="btns-box">
      <el-row :gutter="0">
        <el-col :span="6"><div class="grid-content" /></el-col>
        <el-col :span="12"><div class="grid-content" />
          <el-button color="#f56c6c" @click="onStart" round>Start</el-button>
        </el-col>
        <el-col :span="6"><div class="grid-content" /></el-col>
      </el-row>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import Header from '@/components/HeaderComponent.vue'

const router = useRouter();

const onStart = () => {
  router.replace({ path: '/process' })
}
</script>

<style lang="scss" scoped>
.page{
  width: 100%;
  .welcome{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0;
    img{
      width: 80%;
      margin: 0 auto;
    }
  }
  .description{
    width: 100%;
    font-size: 18px;
    line-height: 1.6;
    color: #333333;
    padding: 0 30px;
    box-sizing: border-box;
  }
  .btns-box{
    width: 100%;
    margin-top: 40px;
  }
}
</style>