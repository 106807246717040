<template>
  <div class="header">
    <div v-if="props.progressShow" class="progress-box">
      <el-progress :percentage="props.percent" :stroke-width="20" color="#eebe77">
        <div class="progress-text">{{ props.currentIndex }}/{{ props.total }}</div>
      </el-progress>
    </div>
    <div class="intro-info">description description description description description description description description description</div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'

const props = defineProps({
  progressShow: { type: Boolean, default: false },
  percent: { type: Number },
  currentIndex: { type: Number },
  total: { type: Number },
});

</script>

<style lang="scss" scoped>
.header{
  width: 100%;
  background-color: #f56c6c;
  padding-top: 30px;
  position: relative;
  .progress-box{
    width: calc(100% - 40px);
    margin-left: 20px;
    .progress-text{
      font-size: 18px;
      color: #ffffff;
      text-align: right;
    }
  }
  .intro-info{
    background: url('@/assets/images/bg.png') 100% 50% no-repeat;
    background-size: auto 100%;
    font-size: 15px;
    color: #ffffff;
    padding: 20px;
    text-indent: 0px;
  }
}
:deep(.el-progress-bar__outer){
  background-color: #ffffff;
}
</style>